<template>
  <div>
    <CCard> 
      <CCardHeader>
        Games
        <CButton square class='float-right' color='info' size='sm' variant='outline' @click='syncPSGames' :disabled='gameSyncing'>
          Sync (Playstar) <span class='spinner-border spinner-border-sm' role='status' aria-hidden='true' v-if='gameSyncing'></span>
        </CButton>
        <CButton square class='float-right' color='info' size='sm' variant='outline' @click='syncJKGames' :disabled='gameSyncing'>
          Sync (Joker) <span class='spinner-border spinner-border-sm' role='status' aria-hidden='true' v-if='gameSyncing'></span>
        </CButton>
        <CButton square class='float-right' color='info' size='sm' variant='outline' @click='syncFKGames' :disabled='gameSyncing'>
          Sync (Funky) <span class='spinner-border spinner-border-sm' role='status' aria-hidden='true' v-if='gameSyncing'></span>
        </CButton>
        <CButton square class='float-right' color='info' size='sm' variant='outline' @click='syncBGGames' :disabled='gameSyncing'>
          Sync (Bgaming) <span class='spinner-border spinner-border-sm' role='status' aria-hidden='true' v-if='gameSyncing'></span>
        </CButton>
        <CButton square class='float-right' color='info' size='sm' variant='outline' @click='syncOPGames' :disabled='gameSyncing'>
          Sync (Onlyplay) <span class='spinner-border spinner-border-sm' role='status' aria-hidden='true' v-if='gameSyncing'></span>
        </CButton>
        <CButton square class='float-right' color='info' size='sm' variant='outline' @click='syncKGGames' :disabled='gameSyncing'>
          Sync (Kaga) <span class='spinner-border spinner-border-sm' role='status' aria-hidden='true' v-if='gameSyncing'></span>
        </CButton>
        <CButton square class='float-right' color='info' size='sm' variant='outline' @click='syncVPGames' :disabled='gameSyncing'>
          Sync (Vpower) <span class='spinner-border spinner-border-sm' role='status' aria-hidden='true' v-if='gameSyncing'></span>
        </CButton>
      </CCardHeader>
      <CCardBody>

        <div v-if="massOpSelectedItems.length > 0" class='massop form-inline'>
          <span class='itemcount'>{{massOpSelectedItems.length}} items selected</span>
          <CSelect size='sm' :options="[ { value: 'activate', label: 'Activate' }, { value: 'deactivate', label: 'Deactivate' }, { value: 'delete', label: 'Delete' }, { value: 'assignsites', label: 'Assign Sites' }, { value: 'assigntags', label: 'Assign Tags' } ]" :value.sync='massOpAction' @change='resetMassOp'/>
          <multiselect v-model="selectedSites" placeholder="Search for sites" label="name" track-by="code" :options="siteOptions" :multiple="true" :show-labels="false" :custom-label="customLabel" v-if="massOpAction =='assignsites'"></multiselect>
          <multiselect v-model="selectedTags" placeholder="Search for tags" label="name" track-by="code" :options="tagOptions" :multiple="true" :show-labels="false" v-if="massOpAction =='assigntags'"></multiselect>
          <CButton size='sm' variant='outline' color='primary' @click="opModalShow('massops', null)">Submit</CButton>
        </div>

        <CDataTable hover :items='items' :fields='fields' :columnFilter='{ external: true, lazy: true }' :columnFilterValue.sync='currentFilters' :sorter='{ external: true }' :sorterValue='currentSort' @update:sorter-value='changeSort' @update:column-filter-value='changeFilter' :loading='isLoading' :responsive='true'>
          
          <template #massop='{item}'>
            <td><input type='checkbox' :value='item._id' v-model='massOpSelectedItems'></td>
          </template>

          <template #image="{item}">
            <td>
              <img v-if="item.provider == 'BG'" v-bind:src="'https://cdn.s777.club/web/bgaming/' + item.providerID + '.png'" style="max-width:90px;"/>
              <img v-if="item.provider == 'FK'" v-bind:src="'https://cdn.s777.club/web/funky/' + item.providerID + '.jpg'" style="max-width:90px;"/>
              <img v-if="item.provider == 'JK'" v-bind:src="'https://cdn.s777.club/web/joker/' + item.providerID + '.png'" style="max-width:90px;"/>
              <img v-if="item.provider == 'PS'" v-bind:src="'https://cdn.s777.club/web/playstar/' + item.providerID + '.jpeg'" style="max-width:90px;"/>
              <img v-if="item.provider == 'OP'" :src="'https://cdn.s777.club/web/onlyplay/' + item.providerID.replace(':', '') + '.png'" style="max-width:90px;"/>
              <img v-if="item.provider == 'KG'" :src="'https://cdn.s777.club/web/kaga/' + item.providerID + '.png'" style="max-width:90px;"/>
              <img v-if="item.provider == 'VP'" :src="'https://cdn.s777.club/web/vpower/' + item.providerID + '.jpg'" style="max-width:90px;"/>
            </td>
          </template>

          <template #name="{item}">
            <td>
              {{item.name}}<br/>
              {{item.providerID}}
            </td>
          </template>

          <template #provider="{item}">
            <td>
              <div v-if="item.provider == 'BG'">Bgaming</div>
              <div v-if="item.provider == 'FK'">Funky</div>
              <div v-if="item.provider == 'JK'">Joker Gaming</div>
              <div v-if="item.provider == 'KG'">Kaga</div>
              <div v-if="item.provider == 'OP'">Onlyplay</div>
              <div v-if="item.provider == 'PS'">Playstar</div>
              <div v-if="item.provider == 'VP'">Vpower</div>
              <div v-if="item.provider == 'S7'">S777</div>
            </td>
          </template>

          <template #type="{item}">
            <td>
              <CBadge v-if="item.type =='SLT'" color="info">Slots</CBadge>
              <CBadge v-if="item.type =='ARC'" color="info">Arcade</CBadge>
              <CBadge v-if="item.type =='FTB'" color="info">Fish Table</CBadge>
              <CBadge v-if="item.type =='LOT'" color="info">Lottery</CBadge>
              <CBadge v-if="item.type =='ECA'" color="info">ECasino</CBadge>
              <CBadge v-if="item.type =='OTH'" color="info">Others</CBadge>
            </td>
          </template>

          <template #status='{item}'>
            <td>
              <CBadge v-if="item.status == 0" color="info">Pending Publish</CBadge>
              <CBadge v-if="item.status == 1" color="success">Active</CBadge>
              <CBadge v-if="item.status == 2" color="danger">Inactive</CBadge>
            </td>
          </template>

          <template #massop-filter='{item}'>
            <input type='checkbox' v-model='massOpSelectAll' @click='massOpSelect' style='margin:0 auto; display:block;'>
          </template>

          <template #provider-filter="{item}">
            <CSelect class='table-select' size='sm' v-model='currentFilters.provider' :options="[ { value: null, label: 'All' }, { value: 'BG', label: 'Bgaming' }, { value: 'FK', label: 'Funky' }, { value: 'JK', label: 'Joker Gaming' }, { value: 'KG', label: 'Kaga' }, { value: 'OP', label: 'Onlyplay' }, { value: 'PS', label: 'Playstar' }, { value: 'VP', label: 'Vpower' }, { value: 'S7', label: 'S777' } ]" @change="changeSelectFilter($event,'provider')" />  
          </template>

          <template #type-filter="{item}">
            <CSelect class='table-select' size='sm' v-model='currentFilters.type' :options="[ { value: null, label: 'All' }, { value: 'FTB', label: 'Fish Table' }, { value: 'SLT', label: 'Slots' }, { value: 'ARC', label: 'Arcade' }, { value: 'LOT', label: 'Lottery' }, { value: 'ECA', label: 'ECasino' }, { value: 'OTH', label: 'Others' } ]" @change="changeSelectFilter($event,'type')" />  
          </template>

          <template #status-filter='{item}'>
            <CSelect class='table-select' size='sm' v-model='currentFilters.status' :options="[ { value: null, label: 'All' }, { value: 0, label: 'Pending Publish' }, { value: 1, label: 'Active' }, { value: 2, label: 'Inactive' } ]" @change="changeSelectFilter($event, 'status')"/>  
          </template>

          <template #operations-filter='{item}'>
            <CSelect class='table-select' size='sm' v-model='itemsPerPage' :options="[ { value: 10, label: '10' }, { value: 50, label: '50' }, { value: 100, label: '100' }, { value: 500, label: '500' }, { value: 1000, label: '1000' } ]" @change='changeItemsPerPage($event)'/>  
          </template>

          <template #operations='{item, index}'>
            <td class='py-2'>
              <CButtonGroup>
                <CButton square color='info' size='sm' variant='outline' @click="$router.push({name: 'Edit Game', params: { id: item._id }})">
                  <CIcon name='cil-pencil'/>
                </CButton>
                <CButton square color='success' size='sm' variant='outline' v-if='item.status == 0 || item.status == 2' @click="opModalShow('unsuspend', item)">
                  <CIcon name='cil-lock-locked'/>
                </CButton>
                <CButton square color='warning' size='sm' variant='outline' v-if='item.status == 1' @click="opModalShow('suspend', item)">
                  <CIcon name='cil-lock-locked'/>
                </CButton>
                <CButton square color='danger' size='sm' variant='outline' @click="opModalShow('delete', item)">
                  <CIcon name='cil-trash'/>
                </CButton>
              </CButtonGroup>
            </td>
          </template>

          <template v-slot:loading>
            <CElementCover :boundaries="[{ sides: ['top'], query: 'td' }, { sides: ['bottom'], query: 'tbody' }]">
              <CSpinner color='secondary'/>
            </CElementCover>
          </template>

          <template v-slot:no-items-view>
            <div class='text-center my-5'>
              No data
            </div>
          </template>

        </CDataTable>
        <CPagination align='center' :activePage.sync='currentPage' :pages='pages' @update:activePage='changePage'/>
      </CCardBody>

    </CCard>

    <CModal :title='opModalTitle' :color='opModalColor' :centered='true' :show.sync='opModal' @update:show='opModalConfirm'>
      {{ opModalText }}
      <div v-if="opModalOperation == 'massops' && massOpAction == 'assignsites'" style='text-align:center;'>{{selectedSitesValue}}</div>
      <div v-if="opModalOperation == 'massops' && massOpAction == 'assigntags'" style='text-align:center;'>{{selectedTagsValue}}</div>
    </CModal>

  </div>
</template>

<script>
import apiGame from '@/api/game'
import apiSite from '@/api/site'
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.min.css'

const items = [
]

const fields = [
  { key: 'massop', label: '', _style: 'width:1%', sorter: false, filter: true },
  { key: 'image', _style:'min-width:80px', sorter: false, filter: false },
  { key: 'provider', _style:'min-width:80px' },
  { key: 'name', _style:'min-width:80px' },
  { key: 'type', _style:'min-width:80px' },
  { key: 'status', _style:'min-width:50px;' },
  { key: 'createdAt', label: 'Created', _style:'min-width:50px;', filter: false },
  { key: 'operations', label: '', _style: 'width:1%', sorter: false, filter: true }
]

export default {
  name: 'ListGame',
  components: {
    Multiselect
  },
  data () {
    return {
      isLoading: false,
      gameSyncing: false,
      items,
      itemsPerPage: 10,
      fields,
      pages: 0,
      currentPage: 1,
      currentSortColumn: null,
      currentSortOrder: null,
      currentFilters: {},
      currentSort: {},
      opModal: false,
      opModalOperation: '',
      opModalTitle: '',
      opModalText: '',
      opModalColor: '',
      opModalItem: null,
      massOpSelectAll: false,
      massOpSelectedItems: [],
      massOpAction: 'activate',
      selectedSites: [],
      selectedSitesValue: [],
      siteOptions: [],
      selectedTags: [],
      tagOptions: [ // hard code tags here first
        { name: 'Popular', code: 'popular' },
        { name: 'New', code: 'new' },
        { name: 'Top Rated', code: 'toprated' }
      ],
      selectedTagsValue: []
    }
  },
  watch: {
    '$route.query': {
      immediate: true,
      handler(newVal) {
        if(Object.keys(newVal).length == 0){
          this.currentPage = 1
          this.currentSortColumn = null
          this.currentSortOrder = null
          this.currentFilters = {}
          this.itemsPerPage = 10
        }else{
          if(newVal.page) { this.currentPage = parseInt(newVal.page) }
          if(newVal.sortColumn) { this.currentSortColumn = newVal.sortColumn }
          if(newVal.sortOrder) { this.currentSortOrder = parseInt(newVal.sortOrder) }
          if(newVal.filters) { this.currentFilters = JSON.parse(decodeURI(newVal.filters)) }
          if(newVal.sortColumn && newVal.sortOrder){
            this.currentSort = {column: newVal.sortColumn, asc: (newVal.sortOrder == 1) ? true : false }
          }
          if(newVal.itemsPerPage) { this.itemsPerPage = parseInt(newVal.itemsPerPage) }
        }
        this.massOpSelectAll = false
        this.massOpSelectedItems = []
        this.fetchList(this.currentPage, this.itemsPerPage, this.currentSortColumn, this.currentSortOrder, this.currentFilters)
      }
    },
    'selectedSites': function() {
      this.selectedSitesValue = []
      this.selectedSites.forEach((site) => {
        this.selectedSitesValue.push(site.code)
      })
    },
    'selectedTags': function() {
      this.selectedTagsValue = []
      this.selectedTags.forEach((tag) => {
        this.selectedTagsValue.push(tag.code)
      })
    }
  },
  created() {
    apiSite.list(1, 200, null, null, {status:0}).then(response => {
      this.siteOptions = response.data.data
    }).catch((error) => {
      this.$toast.error('Error loading sites.', { position: 'top-right', timeout: 5000, hideProgressBar: true })
    })
  },
  methods: {
    
    massOpSelect() {
      this.massOpSelectedItems = []
      if (!this.massOpSelectAll) {
        for (let i in this.items) {
          this.massOpSelectedItems.push(this.items[i]._id);
        }
      }
    },
    resetMassOp(){
      this.selectedSites = []
      this.selectedSitesValue = []
      this.selectedTags = []
      this.selectedTagsValue = []
    },
    customLabel ({ name, domain, code }) {
      return `${code} – ${name} (${domain})`
    },

    /* Table List Functions */
    
    fetchList(page, limit, sortColumn, sortOrder, filters) {
      this.isLoading = true
      apiGame.list(page, limit, sortColumn, sortOrder, filters).then(response => {
        this.items = response.data.data
        this.pages = Math.ceil(response.data.count / this.itemsPerPage)
        this.isLoading = false
      }).catch((error) => {
        this.$toast.error('Error loading list.', { position: 'top-right', timeout: 5000, hideProgressBar: true })
      })
    },
    changePage(val) {
      this.currentPage = val
      this.$router.push({ name: 'List Game', query: { page: this.currentPage, sortColumn: this.currentSortColumn, sortOrder: this.currentSortOrder, filters: encodeURI(JSON.stringify(this.currentFilters)), itemsPerPage: this.itemsPerPage } })
    },
    changeSort(val) {
      this.currentSortColumn = val.column
      this.currentSortOrder = (val.asc) ? 1 : -1;
      this.$router.push({ name: 'List Game', query: { page: this.currentPage, sortColumn: this.currentSortColumn, sortOrder: this.currentSortOrder, filters: encodeURI(JSON.stringify(this.currentFilters)), itemsPerPage: this.itemsPerPage } })
    },
    changeSelectFilter(event, filter) {
      if(filter == 'provider'){
        this.currentFilters['provider'] = event.target.value
        if(!event.target.value){
         delete this.currentFilters['provider'] 
        }
      }
      if(filter == 'status'){
        this.currentFilters['status'] = parseInt(event.target.value)
        if(!event.target.value){
         delete this.currentFilters['status'] 
        }
      }
      if(filter == 'type'){
        this.currentFilters['type'] = event.target.value
        if(!event.target.value){
         delete this.currentFilters['type'] 
        }
      }
      this.$router.push({ name: 'List Game', query: { page: this.currentPage, sortColumn: this.currentSortColumn, sortOrder: this.currentSortOrder, filters: encodeURI(JSON.stringify(this.currentFilters)), itemsPerPage: this.itemsPerPage } })
    },
    changeFilter(val) {
      if(val.name == ''){
        delete this.currentFilters['name'] 
      }else{
        this.currentFilters['name'] = val.name
      }
      this.$router.push({ name: 'List Game', query: { page: this.currentPage, sortColumn: this.currentSortColumn, sortOrder: this.currentSortOrder, filters: encodeURI(JSON.stringify(this.currentFilters)), itemsPerPage: this.itemsPerPage } })
    },
    changeItemsPerPage(event){
      this.$router.push({ name: 'List Game', query: { page: this.currentPage, sortColumn: this.currentSortColumn, sortOrder: this.currentSortOrder, filters: encodeURI(JSON.stringify(this.currentFilters)), itemsPerPage: event.target.value } })
    },
    

    /* List Item Operations */

    opModalShow(operation, item) {
      this.opModal = true
      this.opModalOperation = operation
      this.opModalItem = item
      if(operation == 'unsuspend' || operation == 'suspend'){
        if(operation == 'unsuspend'){
          this.opModalTitle = 'Activate Game ' + item.cashtag
          this.opModalText = 'Are you sure you wish to activate this game "' + item.name + '" ?'
          this.opModalColor = 'success'
        }else{
          this.opModalTitle = 'Deactivate Game ' + item.cashtag
          this.opModalText = 'Are you sure you wish to deactivate this game "' + item.name + '" ?'
          this.opModalColor = 'warning'
        }
      }
      if(operation == 'delete'){
        this.opModalTitle = 'Delete Game ' + item.cashtag
        this.opModalText = 'Are you sure you wish to delete this game "' + item.name + '" ?'
        this.opModalColor = 'danger'
      }

      if(operation == 'massops'){
        if(this.massOpAction == 'activate'){
          this.opModalTitle = 'Activate Games'
          this.opModalText = 'Are you sure you wish to activate ' + this.massOpSelectedItems.length +' selected games?'
          this.opModalColor = 'success'
        }else if(this.massOpAction == 'deactivate'){
          this.opModalTitle = 'Deactivate Games'
          this.opModalText = 'Are you sure you wish to deactivate ' + this.massOpSelectedItems.length +' selected games?'
          this.opModalColor = 'warning'
        }else if(this.massOpAction == 'delete'){
          this.opModalTitle = 'Delete Games'
          this.opModalText = 'Are you sure you wish to delete ' + this.massOpSelectedItems.length +' selected games?'
          this.opModalColor = 'danger'
        }else if(this.massOpAction == 'assignsites'){
          this.opModalTitle = 'Assign Sites to Games'
          this.opModalText = 'Are you sure you wish to assign the following sites to ' + this.massOpSelectedItems.length +' selected games?'
          this.opModalColor = 'info'
        }else if(this.massOpAction == 'assigntags'){
          this.opModalTitle = 'Assign Tags to Games'
          this.opModalText = 'Are you sure you wish to assign the following tags to ' + this.massOpSelectedItems.length +' selected games?'
          this.opModalColor = 'info'
        }
      }
    },
    opModalConfirm(show, event, confirm) {
      if(confirm){
        this.opModal = false
        this.isLoading = true
        if(this.opModalOperation == 'unsuspend' || this.opModalOperation == 'suspend'){
          var status = (this.opModalOperation == 'suspend') ? 2 : 1
          apiGame.updateStatus(this.opModalItem._id, status).then(response => {
            this.clearOpModalProps()
            this.$router.replace({ name: 'List Game', query: { page: this.currentPage, sortColumn: this.currentSortColumn, sortOrder: this.currentSortOrder, filters: encodeURI(JSON.stringify(this.currentFilters)), itemsPerPage: this.itemsPerPage, status: require('crypto').randomBytes(24).toString('hex') } })
            var successtext = (status == 2) ? 'suspended' : 'activated'
            this.$toast.success('Successfully ' + successtext + '.', { position: 'top-right', timeout: 5000, hideProgressBar: true })
          }).catch((error) => {
            var errortext = (status == 2) ? 'suspending' : 'activating'
            this.$toast.error('Error ' + errortext + '.', { position: 'top-right', timeout: 5000, hideProgressBar: true })
            this.clearOpModalProps()
          })
        }
        if(this.opModalOperation == 'delete'){
          apiGame.delete(this.opModalItem._id).then(response => {
            this.clearOpModalProps()
            this.$router.replace({ name: 'List Game', query: { page: this.currentPage, sortColumn: this.currentSortColumn, sortOrder: this.currentSortOrder, filters: encodeURI(JSON.stringify(this.currentFilters)), itemsPerPage: this.itemsPerPage, deleted: require('crypto').randomBytes(24).toString('hex') } })
            this.$toast.success('Successfully deleted.', { position: 'top-right', timeout: 5000, hideProgressBar: true })
          }).catch((error) => {
            this.$toast.error('Error deleting.', { position: 'top-right', timeout: 5000, hideProgressBar: true })
            this.clearOpModalProps()
          })
        }
        if(this.opModalOperation == 'massops'){
          apiGame.updateBulk(this.massOpSelectedItems, this.massOpAction, this.selectedSitesValue, this.selectedTagsValue).then(response => {
            this.clearOpModalProps()
            this.$router.replace({ name: 'List Game', query: { page: this.currentPage, sortColumn: this.currentSortColumn, sortOrder: this.currentSortOrder, filters: encodeURI(JSON.stringify(this.currentFilters)), itemsPerPage: this.itemsPerPage, bulk: require('crypto').randomBytes(24).toString('hex') } })
            this.$toast.success('Successfully completed bulk update.', { position: 'top-right', timeout: 5000, hideProgressBar: true })
          }).catch((error) => {
            this.$toast.error('Error updating.', { position: 'top-right', timeout: 5000, hideProgressBar: true })
            this.clearOpModalProps()
          })
        }
      }else{
        this.clearOpModalProps()
      }
    },
    clearOpModalProps(){
      this.opModal = false
      this.opModalOperation = ''
      this.opModalTitle = ''
      this.opModalText = ''
      this.opModalColor = ''
      this.opModalItem = null
      this.isLoading = false

      this.massOpSelectedItems = []
      this.resetMassOp()
    },
    syncPSGames() {
      this.gameSyncing = true
      apiGame.syncPlaystar().then(response => {
        this.$toast.success('Successfully synced playstar games.', { position: 'top-right', timeout: 5000, hideProgressBar: true })
        this.gameSyncing = false
        this.$router.replace({ name: 'List Game', query: { page: this.currentPage, sortColumn: this.currentSortColumn, sortOrder: this.currentSortOrder, filters: encodeURI(JSON.stringify(this.currentFilters)), itemsPerPage: this.itemsPerPage, sync: require('crypto').randomBytes(24).toString('hex') } })
      }).catch((err) => {
        this.$toast.error('Error syncing playstar games.', { position: 'top-right', timeout: 5000, hideProgressBar: true })
        this.gameSyncing = false
      })
    },
    syncJKGames() {
      this.gameSyncing = true
      apiGame.syncJoker().then(response => {
        this.$toast.success('Successfully synced joker games.', { position: 'top-right', timeout: 5000, hideProgressBar: true })
        this.gameSyncing = false
        this.$router.replace({ name: 'List Game', query: { page: this.currentPage, sortColumn: this.currentSortColumn, sortOrder: this.currentSortOrder, filters: encodeURI(JSON.stringify(this.currentFilters)), itemsPerPage: this.itemsPerPage, sync: require('crypto').randomBytes(24).toString('hex') } })
      }).catch((err) => {
        this.$toast.error('Error syncing joker games.', { position: 'top-right', timeout: 5000, hideProgressBar: true })
        this.gameSyncing = false
      })
    },
    syncFKGames() {
      this.gameSyncing = true
      apiGame.syncFunky().then(response => {
        this.$toast.success('Successfully synced funky games.', { position: 'top-right', timeout: 5000, hideProgressBar: true })
        this.gameSyncing = false
        this.$router.replace({ name: 'List Game', query: { page: this.currentPage, sortColumn: this.currentSortColumn, sortOrder: this.currentSortOrder, filters: encodeURI(JSON.stringify(this.currentFilters)), itemsPerPage: this.itemsPerPage, sync: require('crypto').randomBytes(24).toString('hex') } })
      }).catch((err) => {
        this.$toast.error('Error syncing funky games.', { position: 'top-right', timeout: 5000, hideProgressBar: true })
        this.gameSyncing = false
      })
    },
    syncBGGames() {
      this.gameSyncing = true
      apiGame.syncBgaming().then(response => {
        this.$toast.success('Successfully synced bgaming games.', { position: 'top-right', timeout: 5000, hideProgressBar: true })
        this.gameSyncing = false
        this.$router.replace({ name: 'List Game', query: { page: this.currentPage, sortColumn: this.currentSortColumn, sortOrder: this.currentSortOrder, filters: encodeURI(JSON.stringify(this.currentFilters)), itemsPerPage: this.itemsPerPage, sync: require('crypto').randomBytes(24).toString('hex') } })
      }).catch((err) => {
        this.$toast.error('Error syncing bgaming games.', { position: 'top-right', timeout: 5000, hideProgressBar: true })
        this.gameSyncing = false
      })
    },
    syncOPGames() {
      this.gameSyncing = true
      apiGame.syncOnlyplay().then(response => {
        this.$toast.success('Successfully synced onlyplay games.', { position: 'top-right', timeout: 5000, hideProgressBar: true })
        this.gameSyncing = false
        this.$router.replace({ name: 'List Game', query: { page: this.currentPage, sortColumn: this.currentSortColumn, sortOrder: this.currentSortOrder, filters: encodeURI(JSON.stringify(this.currentFilters)), itemsPerPage: this.itemsPerPage, sync: require('crypto').randomBytes(24).toString('hex') } })
      }).catch((err) => {
        this.$toast.error('Error syncing onlyplay games.', { position: 'top-right', timeout: 5000, hideProgressBar: true })
        this.gameSyncing = false
      })
    },
    syncKGGames() {
      this.gameSyncing = true
      apiGame.syncKaga().then(response => {
        this.$toast.success('Successfully synced kaga games.', { position: 'top-right', timeout: 5000, hideProgressBar: true })
        this.gameSyncing = false
        this.$router.replace({ name: 'List Game', query: { page: this.currentPage, sortColumn: this.currentSortColumn, sortOrder: this.currentSortOrder, filters: encodeURI(JSON.stringify(this.currentFilters)), itemsPerPage: this.itemsPerPage, sync: require('crypto').randomBytes(24).toString('hex') } })
      }).catch((err) => {
        this.$toast.error('Error syncing kaga games.', { position: 'top-right', timeout: 5000, hideProgressBar: true })
        this.gameSyncing = false
      })
    },
    syncVPGames() {
      this.gameSyncing = true
      apiGame.syncVpower().then(response => {
        this.$toast.success('Successfully synced vpower games.', { position: 'top-right', timeout: 5000, hideProgressBar: true })
        this.gameSyncing = false
        this.$router.replace({ name: 'List Game', query: { page: this.currentPage, sortColumn: this.currentSortColumn, sortOrder: this.currentSortOrder, filters: encodeURI(JSON.stringify(this.currentFilters)), itemsPerPage: this.itemsPerPage, sync: require('crypto').randomBytes(24).toString('hex') } })
      }).catch((err) => {
        this.$toast.error('Error syncing vpower games.', { position: 'top-right', timeout: 5000, hideProgressBar: true })
        this.gameSyncing = false
      })
    }
  }
}
</script>
